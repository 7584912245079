import { Dict } from 'mixpanel-browser';

export enum Events {
  // GENERIC
  Error = 'error',
  PermissionMicrophoneShown = 'permission.shown.microphone',
  PermissionMicrophoneGranted = 'permission.granted.microphone',
  PermissionMicrophoneDenied = 'permission.denied.microphone',
  PermissionCameraShown = 'permission.shown.camera',
  PermissionCameraGranted = 'permission.granted.camera',
  PermissionCameraDenied = 'permission.denied.camera',
  // HOST
  HostAuthArrive = 'host.arrive.auth',
  HostPhotoBrowse = 'host.browse.photo',
  HostPhotoSelected = 'host.select.photo',
  HostPhotoClear = 'host.clear.photo',
  HostSignup = 'host.signup',
  HostSignin = 'host.signin',
  HostShareLink = 'host.share.link',
  // STORYTELLER
  StorytellerArrive = 'storyteller.arrive',
  StorytellerAuthArrive = 'storyteller.arrive.auth',
  StorytellerStart = 'storyteller.start',
  StorytellerInputBrowse = 'storyteller.browse.inputs',
  StorytellerInputChange = 'storyteller.change.inputs',
  StorytellerRecordingStart = 'storyteller.start.recording',
  StorytellerRecordingEnd = 'storyteller.end.recording',
  StorytellerPromptNext = 'storyteller.next.prompt',
  StorytellerSignup = 'storyteller.signup',
  StorytellerSignin = 'storyteller.signin',
  // VIEWER
  ViewerArrive = 'viewer.arrive',
  ViewerClipsArrive = 'viewer.arrive.clips',
  ViewerClipWatched = 'viewer.watch.clip',
  ViewerClipDownload = 'viewer.download.clip',
  ViewerShare = 'viewer.share',
  ViewerApp = 'viewer.view.app',
}

export interface AnalyticsService {
  track(event: Events, payload?: Dict): void;
  identify(id: string, props?: Record<string, any>): void;
  identifyTester(): void;
  reset(): void;
  getUserId(): string | null;
}

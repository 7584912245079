import create from 'zustand';

type AnalyticsContextState = {
  timelineEventId: string | null;
};

const initialState: AnalyticsContextState = {
  timelineEventId: null,
};

const analyticsContextStore = create<AnalyticsContextState>(() => initialState);

function setTimelineEventId(timelineEventId: string | null): void {
  analyticsContextStore.setState((state) => ({
    ...state,
    timelineEventId,
  }));
}

function getTimelineEventId(): string | null {
  return analyticsContextStore.getState().timelineEventId;
}

function reset() {
  analyticsContextStore.setState(initialState);
}

export const AnalyticsContextStore = {
  setTimelineEventId,
  getTimelineEventId,
  reset,
};

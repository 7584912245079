import create from 'zustand';

interface RecordingState {
  id: string;
}

const store = create(
  (): RecordingState => ({
    id: 'default',
  }),
);

export function useRecording() {
  return store((state) => state);
}

export function useRecordingId() {
  return store((state) => state.id);
}

export function setRecording(recording: RecordingState) {
  store.setState((state) => ({ ...state, recording }));
}

export function getRecordingId() {
  return store.getState().id;
}

export function setRecordingId(id: string) {
  store.setState((state) => ({ ...state, id }));
}

import { Event } from '@sentry/nextjs';

import { analyticsService } from '../analytics.service';
import { AnalyticsService, Events } from '../analytics.types';

import { WebappAnalyticsService } from './webapp-analytics.types';

export class WebappAnalyticsServiceImpl implements WebappAnalyticsService {
  constructor(
    private sentryOrganization: string,
    private sentryProjectId: string,
    private analyticsService: AnalyticsService,
  ) {}

  onError(event: Event): void {
    if (this.sentryOrganization.length === 0 || this.sentryProjectId.length === 0) {
      return;
    }

    if (!event.event_id) {
      // eslint-disable-next-line no-console
      console.warn('Sentry event does not have an id');
      return;
    }

    // Sentry report URL:
    // https://sentry.io/organizations/{ORG}/issues/?project={PROJECT_ID}}&query={EVENT_ID}
    this.analyticsService.track(Events.Error, {
      sentryUrl: `https://sentry.io/organizations/${this.sentryOrganization}/issues/?project=${this.sentryProjectId}&query=${event.event_id}`,
    });
  }

  onMicrophonePermissionShown(): void {
    this.analyticsService.track(Events.PermissionMicrophoneShown);
  }

  onMicrophonePermissionGranted(): void {
    this.analyticsService.track(Events.PermissionMicrophoneGranted);
  }

  onMicrophonePermissionDenied(): void {
    this.analyticsService.track(Events.PermissionCameraDenied);
  }

  onCameraPermissionShown(): void {
    this.analyticsService.track(Events.PermissionCameraShown);
  }

  onCameraPermissionGranted(): void {
    this.analyticsService.track(Events.PermissionCameraGranted);
  }

  onCameraPermissionDenied(): void {
    this.analyticsService.track(Events.PermissionCameraDenied);
  }
}

export const webappAnalyticsService = new WebappAnalyticsServiceImpl(
  process.env.NEXT_PUBLIC_SENTRY_ORGANIZATION || '',
  process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID || '',
  analyticsService,
);

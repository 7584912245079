import * as Sentry from '@sentry/nextjs';

export function captureException(error: any, catchException = false) {
  Sentry.captureException(error);

  const errorMessage = typeof error === 'string' ? error : error.message;
  console.error(`ERROR - ${errorMessage}${error.stack ? `\n${error.stack}` : ''}`);

  if (!catchException) {
    throw new Error(errorMessage);
  }
}

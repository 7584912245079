export function isSentryEnabled() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.NEXT_PUBLIC_DEBUG_SENTRY === 'true';
  }

  return process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'development';
}

export function getSentryEnvironment() {
  if (process.env.NODE_ENV === 'development') {
    return 'development';
  }

  return process.env.NEXT_PUBLIC_ENV;
}

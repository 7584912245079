import mixpanel, { Dict, Mixpanel } from 'mixpanel-browser';

import { OnboardingStore } from '../../modules/onboarding/onboarding.store';
import { getRecordingId } from '../../modules/recording/recording.state';
import { captureException } from '../../utils/captureException';
import { api } from '../api';

import { AnalyticsService, Events } from './analytics.types';
import { AnalyticsContextStore } from './analytics-context.store';

function getAppContext(): Dict {
  const context: Dict = {};

  const recordingId = getRecordingId();
  if (recordingId !== 'default') {
    context.recordingId = recordingId;
  }

  const useCase = OnboardingStore.getUseCase();
  if (useCase !== null) {
    context.useCase = useCase;
  }

  const timelineEventId = AnalyticsContextStore.getTimelineEventId();
  if (timelineEventId !== null) {
    context.timelineEventId = timelineEventId;
  }

  return context;
}

class MixpanelAnalyticsService implements AnalyticsService {
  constructor(private analyticsService: Mixpanel) {}

  async identify(firebaseUserId: string, props?: Record<string, any>): Promise<void> {
    try {
      const mixpanelUserId = this.getUserId();
      // If firebaseUserId already matches mixpanelUserId, don't do anything
      if (firebaseUserId === mixpanelUserId) {
        // The user can be deleted in the mixpanel console.
        // In this case, we need to call idenitfy again.
        const isUserIdentified = await this.isUserIdentified(firebaseUserId);
        if (isUserIdentified) {
          return;
        }
      }

      this.analyticsService.identify(firebaseUserId);
      if (props != null) {
        this.analyticsService.people.set(props);
      }
    } catch (error) {
      captureException(error);
    }
  }

  identifyTester() {
    if (process.env.NEXT_PUBLIC_DEBUG_MIXPANEL === 'true') {
      this.analyticsService.people.set({ Tester: true });
    }
  }

  reset() {
    this.analyticsService.reset();
  }

  track(event: Events, payload?: Dict): void {
    this.analyticsService.track(event, { ...getAppContext(), ...payload });
  }

  getUserId() {
    return this.analyticsService.get_distinct_id();
  }

  private async isUserIdentified(distinctId: string): Promise<boolean> {
    const { data } = await api.get<{ identified: boolean }>(`/user-analytics/${distinctId}`);
    return data.identified;
  }
}

class ConsoleAnalyticsService implements AnalyticsService {
  identify(id: string, props: Record<string, any>): void {
    console.log(`[CONSOLE-ANALYTICS] Identify "${id}" with props ${JSON.stringify(props)}`);
  }

  identifyTester(): void {
    console.log(`[CONSOLE-ANALYTICS] Identify tester`);
  }

  track(event: Events, payload?: Dict | undefined): void {
    console.log(`[CONSOLE-ANALYTICS] Track "${event}", payload: `, { ...getAppContext(), ...payload });
  }

  reset(): void {
    console.log(`[CONSOLE-ANALYTICS] Reset tester`);
  }

  getUserId() {
    return '[CONSOLE-ANALYTICS] id';
  }
}

function getAnalyicsService(): AnalyticsService {
  if (process.env.NODE_ENV === 'production' || process.env.NEXT_PUBLIC_DEBUG_MIXPANEL === 'true') {
    const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN;
    if (!mixpanelToken) {
      console.error('Missing mixpanel token');
      return new ConsoleAnalyticsService();
    }
    mixpanel.init(mixpanelToken);
    return new MixpanelAnalyticsService(mixpanel);
  }
  return new ConsoleAnalyticsService();
}

export const analyticsService = getAnalyicsService();

var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"895db1ce4d10f1a2490441f661fd09701696c542"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { getSentryEnvironment, isSentryEnabled } from './sentry';
import { webappAnalyticsService } from './src/api/analytics/webapp-analytics/webapp-analytics.service';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0, // Adjust this value in production, or use tracesSampler for greater control
  enabled: isSentryEnabled(),
  environment: getSentryEnvironment(),
  ignoreErrors: ['ResizeObserver loop limit exceeded'], // It is recommended to ignore this error https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
  beforeSend(event) {
    webappAnalyticsService.onError(event);
    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

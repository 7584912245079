import create from 'zustand';
import { persist } from 'zustand/middleware';

import { UseCase } from '../../api/timeline-event/timeline-event.types';

interface Person {
  name: string;
  email: string;
  phoneNumber: string;
}

interface OnboardingState {
  invitedPerson: Person | null;
  isExistingUser: boolean;
  shortRecordUrl: string | null;
  useCase: UseCase | null;
}

const initialState: OnboardingState = {
  invitedPerson: null,
  isExistingUser: false,
  shortRecordUrl: null,
  useCase: null,
};

const onboardingStore = create(persist<OnboardingState>(() => initialState, { name: '@remento/onboarding' }));
const useOnboardingStore = onboardingStore;

function setInvitedPerson(invitedPerson: Person) {
  onboardingStore.setState((state) => ({ ...state, invitedPerson }));
}

function setIsExistingUser(isExistingUser: boolean) {
  onboardingStore.setState((state) => ({ ...state, isExistingUser }));
}

function useIsExistingUser() {
  return useOnboardingStore((state) => state.isExistingUser);
}

function useInvitedPerson() {
  return useOnboardingStore((state) => state.invitedPerson);
}

function setShortRecordUrl(shortRecordUrl: string): void {
  onboardingStore.setState((state) => ({ ...state, shortRecordUrl }));
}

function getShortRecordUrl(): string | null {
  return onboardingStore.getState().shortRecordUrl;
}

function setUseCase(useCase: UseCase): void {
  onboardingStore.setState((state) => ({ ...state, useCase }));
}

function getUseCase(): UseCase | null {
  return onboardingStore.getState().useCase;
}

function reset() {
  onboardingStore.setState(initialState);
}

export const OnboardingStore = {
  setInvitedPerson,
  useInvitedPerson,
  setIsExistingUser,
  useIsExistingUser,
  setShortRecordUrl,
  getShortRecordUrl,
  setUseCase,
  getUseCase,
  reset,
};
